import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from '../components/Home';
import About from '../components/About'
import NextTime from '../components/NextTime'
import Register from '../components/Register'
import Participant from '../components/participant';


// import NotFound from '../pages/NotFound';

const RoutesConfig = () => {
    console.log('inside the routes config function')
  return (
    <Router>
        <Routes>
        <Route exact path="/" element={<Participant />} />
        <Route exact path="/spin" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/nexttime" element={<NextTime />} />
            <Route path="/register" element={<Register />} />
        </Routes>
    </Router>
  );
};

export default RoutesConfig;
