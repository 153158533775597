import React from 'react';
import backgroundImage from '../assets/images/bg.jpg';
import imageAboveText from '../assets/images/ic2.png';

const containerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column', // Ensure items are stacked vertically
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  };

  const textStyle = {
    color:'#273533',
    fontSize: '34', // Adjust font size as needed
    fontWeight: 'bold', // Make text bold
    marginTop: '10px',
  };
  
  const subTextStyle = {
    color:'#273533',
    fontSize: '18px', // Adjust font size as needed
    marginTop: '10px', // Add margin to separate it from the main text
  };

  const imageStyle = {
    width: "154px",
     height: 'auto',
    marginBottom: "25px"
  };
const NextTime = () => {
    return (
        <div style={containerStyle}>
            <img src={imageAboveText} alt="Image Above Text" style={imageStyle} />
            <div style={textStyle}>Better luck next time!</div>
            <div style={subTextStyle}>Thank you for giving your best.</div>
      </div>
      );
};

export default NextTime;