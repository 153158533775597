import React, { useState } from 'react';
import backgroundImage from '../assets/images/bg.jpg';
import wheelBackground from '../assets/images/spin2.png'
import '../App.css';
import WheelComponent from '../components/WheelContainer'
import { useNavigate,useLocation } from "react-router-dom";
import Modal from '../components/Modal'
import RegisterComponent from '../components/Register'
import NextTimeComponent from '../components/NextTime'
import pointerImage from '../assets/images/pin.png'

// const prizes = [
//   "Chic try! Free Blow-dry.", "Say cheese! 15% off on all products.", "Whoa! This was close.", "Groomed it! Free mani-pedi.", "C'mon This happens.",
//   "Awesome! Ladies night dinner for 2.", "Oopsie! You just missed it.", "Coffee-licious! Free Combo: Coffee & Pastry", "Oh No! Nothing here.", "Fittastic! Free Bum Bum Brazil class.",
// ];

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state; 
  console.log('stateRewardName----',stateData);
  const stateRewardName = stateData
  const userId = localStorage.getItem('userId')
  console.log('local--',userId);
  // let spinStopData
  // if(stateRewardName == 'FDC'){
  //   spinStopData = 'Awesome! Ladies night dinner for 2.'
  // }else if(stateRewardName == 'CF'){
  //   spinStopData = 'Coffee-licious! Free Combo: Coffee & Pastry'
  // }else if(stateRewardName == 'RF'){
  //   spinStopData = 'Fittastic! Free Bum Bum Brazil class.'
  // }else if(stateRewardName == 'UHPSAG'){
  //   spinStopData = 'Say cheese! 15% off on all products.'
  // }else if(stateRewardName == 'EVBSBD'){
  //   spinStopData = 'Chic try! Free Blow-dry.'
  // }else if(stateRewardName == 'EVBSMP'){
  //   spinStopData = 'Groomed it! Free mani-pedi.'
  // }
  // else{
  //   spinStopData = stateRewardName
  // }
  const [result, setResult] = useState("");
  const [spinning, setSpinning] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [winnerValue,setWinnerValue] = useState('')
  const [email, setEmail] = useState("");

  const containerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column', // Ensure items are stacked vertically
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    // boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', // Add box shadow
    // borderRadius: '10px', // Optional: add border radius for rounded corners
};

const wheelContainer = {
  // backgroundImage: `url(${wheelBackground})`,
  backgroundSize: 'cover',
  position:'relative'
}

  const spinButton = {
    backgroundColor: 'transparent', // Transparent background
    color: 'black', // Black text color
    border: '2px solid #9E8073', // Gold border
    width: '300px',
    padding: '8px',
    margin: '8px',
    marginTop: "28px"
  };

  

  const spin = () => {
    if (spinning) return;
    setSpinning(true);

    // setTimeout(() => {
    //   // const randomIndex = 5;
    //   // setResult(`Congratulations! You won: ${prizes[randomIndex]}`);
    //   // console.log('prizes[randomIndex]',prizes[randomIndex]);
    //   // setSpinning(false);
    //   // setShowModal(true);
    // }, 2000); // Duration of spinning animation
  };

  const segments = ["Chic try! Free Blow-dry.", "Say cheese! 15% off on all products.", "Whoa! This was close.", "Groomed it! Free mani-pedi.", "C'mon This happens.",
  "Awesome! Ladies night dinner for 2.", "Oopsie! You just missed it.", "Coffee-licious! Free Combo: Coffee & Pastry", "Oh No! Nothing here.", "Fittastic! Free Bum Bum Brazil class.",];
  const segColors = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#FF9000",
    "#EE4040",
    "#F0CF50",
    "#FF9000",
    "#EE4040",
    "#F0CF50",
  ];
  const onFinished = (winner) => {
    console.log('winner--',winner);
    setWinnerValue(winner)
    // Rewards array
    const rewards = [
      "Chic try! Free Blow-dry.",
      "Say cheese! 15% off on all products.",
      "Groomed it! Free mani-pedi.",
      "Awesome! Ladies night dinner for 2.",
      "Coffee-licious! Free Combo: Coffee & Pastry",
      "Fittastic! Free Bum Bum Brazil class."
  ];
  // Check if the winner segment is a reward
  const isWinnerReward = rewards.includes(winner);
  console.log('isWinnerReward',isWinnerReward);
  postData(winner)
  setTimeout(()=>{
    if(isWinnerReward){
    navigate("/register");
  }else{
    navigate("/nexttime")
  }
  },4000)
  
  // isWinnerReward ? navigate("/register") : console.log("Winner is not a reward.");

    
    // navigate("/register");
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  
  const handleClick = ()=>{
    navigate("/register")
  }

  async function postData(winner) {
    console.log('winner',winner);
    console.log('postdata',userId);
    const url = 'http://localhost:4001/userspin';
    const data = {
      "id":userId,
      'spin':'1',
      "winner": winner,
    };
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        console.log('Response:', responseData);
        return responseData.data;
    } catch (error) {
        console.error('There was a problem with the POST request:', error);
    }
  }


  return (
    <>
    <div id="wheelCircle" style={containerStyle}>
      <div className='wheelBgCircle' style={wheelContainer}>
    <WheelComponent
        segments={segments}
        segColors={segColors}
        winningSegment={stateRewardName}
        onFinished={(winner) => onFinished(winner)}
        primaryColor="black"
        primaryColoraround="#ffffffb4"
        contrastColor="white"
        buttonText="Spin"
        spinStopData={stateRewardName}
        isOnlyOnce={false}
        size={290}
        upDuration={1000}
        downDuration={2000}
      />
      <img src={pointerImage} alt="Pointer Image" style={{ position: 'absolute', top: '-13px', left: '50%', width: '30px',height: '40px', transform: 'translateX(-50%)', zIndex: 999 }} />
      </div>

      <div style={{marginTop:'10px'}}>
        <button onClick={spin} disabled={spinning} className='submitButton'>SPIN THE WHEEL</button>
    </div>
    </div>
    </>
    
  //   <div>
  //   <button onClick={spin} disabled={spinning} style={spinButton}><p>SPIN THE WHEEL</p></button>
  // </div>
  );
}

export default Home;
