import React from 'react';
import '../styles/participant.css';
import '../App.css';
import wheel from '../assets/img/wheel.png'
import { useNavigate } from "react-router-dom";
import backgroundImage from '../assets/images/bg.jpg';


const containerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column', // Ensure items are stacked vertically
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  };

function Participant() {
    const navigate = useNavigate();

    async function postData() {
        const url = 'http://localhost:4001/users';
        const data = { "participate": 1 };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            console.log('Response:', responseData);
            return responseData.data;
        } catch (error) {
            console.error('There was a problem with the POST request:', error);
        }
    }

    const participate = async () => {
        const data = await postData()
        const {selectedRewardName,userId} = data
        console.log('consoling data selectedRewardName', selectedRewardName);
        console.log('consoling data userId', userId);

        localStorage.setItem('userId',data.userId)
        navigate("/spin", { state: selectedRewardName }); // Pass data as state
    }

    return (
        <div className="" style={containerStyle}>
            <div className="spinner-container" style={{ paddingTop: '-1%', backgroundColor: "", zIndex: 10, position: 'relative', display: 'flex', justifyContent: 'center' }}>
                <img style={{ maxWidth: '100%' }} src={wheel} alt="spinner" />
            </div>

            <div className="overBg">
                <div className="bgSpace" style={{ marginTop: '10%' }}>
                    <h1>Take your<br /> super spin,</h1>
                    <h2>win exclusive vouchers.</h2>
                    <p>
                        Spin your way to celebrate International Women’s Day! Stand a chance to win special vouchers while we honour the incredible achievements of women worldwide.
                    </p>

                    <a onClick={participate} className='submitButton' href="#">Participate</a>
                </div>
            </div>
        </div>
    );
}

export default Participant;
