import React,{useState} from 'react';
import backgroundImage from '../assets/images/bg.jpg';
import imageAboveText from '../assets/images/ic.png';
import congrats from '../assets/images/congratz.png';
import { useNavigate,useLocation } from "react-router-dom";
import '../index.css'


const containerStyle = {
  position: 'relative', // Set position to relative
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
};

const textStyle = {
  color: 'black',
  fontSize: '20px',
  fontWeight: 'light',
};

const subTextStyle = {
  color: 'black',
  fontSize: '18px',
  marginTop: '20px', // Decrease margin to move the text up
  textAlign: 'center',
};

const inputStyle = {
  width: '330px',
  height: '40px',
  padding: '10px',
  marginTop: '20px',
  border: '2px solid #9E8073',
  backgroundColor: 'transparent',
  borderRadius: '5px',
  textAlign: 'center',
};



const congratsStyle = {
  width: '300px',
  height: '109px',
  marginBottom: '10px', // Adjust margin to move the congrats image up
};

const imageStyle = {
  width: '174px',
  height: 'auto',
  marginBottom: '20px',
  marginTop: '-20px', // Adjust margin to move the image up
};

const Register = () => {
  
  const [email, setEmail] = useState('');
  const userId = localStorage.getItem('userId')
  console.log('local--register--',userId);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  
  const handleSubmit = async() => {
    const url = 'http://localhost:4001/userregister';
        const data = { "id": userId,'email':email };
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            if(responseData){
              setEmail('')
            }
            console.log('Response:', responseData);
            return responseData.data;
        } catch (error) {
            console.error('There was a problem with the POST request:', error);
        }
  };
  
  return (
    <div style={containerStyle}>
      <img src={imageAboveText} alt="Image Above Text" style={imageStyle} />
      <img src={congrats} alt="Image Above Text" style={congratsStyle} />
      <div style={textStyle}>On your super spin!</div>
      <div style={subTextStyle}>
        <div>Enter your email</div>
        <div>to receive the gift voucher</div>
      </div>
      <input type='email' placeholder='Email' className='inputStyle' value={email} onChange={handleEmailChange}/>
      <button onClick={handleSubmit} className='submitButton'>Submit</button>
    </div>
  );
};

export default Register;
