import React, { useEffect, useState } from "react";
import "../App.css";
import wheelImage from '../assets/images/spin.png'
import seg1Image from '../assets/images/01.png'
import seg2Image from '../assets/images/2.png'
import seg3Image from '../assets/images/3.png'
import seg4Image from '../assets/images/4.png'
import seg5Image from '../assets/images/5.png'
import seg6Image from '../assets/images/6.png'
import seg7Image from '../assets/images/7.png'
import seg8Image from '../assets/images/8.png'
import seg9Image from '../assets/images/9.png'
import seg10Image from '../assets/images/10.png'
import seg11Image from '../assets/images/11.png'
import wheelCenterImage from '../assets/images/11.png'
import circ from '../assets/images/circ.png'


const WheelComponent = ({
    segments,
    segColors,
    winningSegment,
    spinStopData,
    onFinished,
    onRotate,
    onRotatefinish,
    primaryColor,
    primaryColoraround,
    contrastColor,
    buttonText,
    isOnlyOnce = true,
    size = 290,
    upDuration = 1000,
    downDuration = 100,
    fontFamily = "proxima-nova",
    width = 100,
    height = 100
}) => {
    let currentSegment = "";
    let isStarted = false;
    const [isFinished, setFinished] = useState(false);
    let timerHandle = 0;
    const timerDelay = segments.length;
    let angleCurrent = 0;
    let angleDelta = 0;
    let canvasContext = null;
    let maxSpeed = Math.PI / `${segments.length}`;
    const upTime = segments.length * upDuration;
    const downTime = segments.length * downDuration;
    let spinStart = 0;
    let frames = 0;
    const centerX = 300;
    const centerY = 300;
    useEffect(() => {
        const wheelClickHandler = () => spin();
        const rootClickHandler = () => spin();
        
        document.getElementById("wheel").addEventListener("click", wheelClickHandler);
        document.getElementById("wheelCircle").addEventListener("click", rootClickHandler);
    
        wheelInit();
        setTimeout(() => {
            window.scrollTo(0, 1);
        }, 0);
    
        return () => {
            // document.getElementById("wheel").removeEventListener("click", wheelClickHandler);
            // document.getElementById("wheelCircle").removeEventListener("click", rootClickHandler);
        };
    }, []);
    
    const wheelInit = () => {
        initCanvas();
        wheelDraw();

    };

    const initCanvas = () => {
        let canvas = document.getElementById("canvas");
        if (navigator.appVersion.indexOf("MSIE") !== -1) {
            canvas = document.createElement("canvas");
            canvas.setAttribute("width", width);
            canvas.setAttribute("height", height);
            canvas.setAttribute("id", "canvas");
            document.getElementById("wheel").appendChild(canvas);
        }
        // canvas.addEventListener("click", spin, false);
        canvasContext = canvas.getContext("2d");
    };

    const spin = () => {
        console.log('inside spin');
        isStarted = true;
        if (timerHandle === 0) {
            spinStart = new Date().getTime();
            maxSpeed = Math.PI / segments.length;
            frames = 0;
            timerHandle = setInterval(onTimerTick, timerDelay);
        }
    };
    const onTimerTick = () => {
        frames++;
        draw();
    
        const currentTime = new Date().getTime();
        const elapsedTime = currentTime - spinStart;
        let progress = 0;
        let finished = false;
    
        const spinDuration = 4000; // Specify the duration in milliseconds
        const stopValue = spinStopData;
        const slowDownStartTime = spinDuration - 1000; // Calculate the time to start slowing down
    
        if (elapsedTime >= spinDuration) {
            if (currentSegment !== stopValue) {
                if (elapsedTime < slowDownStartTime) {
                    // Calculate progress for the spin up phase
                    progress = elapsedTime / slowDownStartTime;
                    angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
                } else {
                    // Calculate angular velocity for the slowdown phase
                    const remainingTime = spinDuration - elapsedTime;
                    const slowDownFactor = 8 - (remainingTime / (spinDuration - slowDownStartTime));
                    angleDelta = maxSpeed * slowDownFactor;
                }
            } else {
                finished = true; // Set finished to true if the wheel reaches the stopValue
            }
        } else {
            // Calculate progress within the spinning duration
            progress = elapsedTime / spinDuration;
            angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
        }
    
        angleCurrent += angleDelta;
        angleCurrent %= Math.PI * 2; // Keep the angle within the range of 0 to 2π
    
        if (finished) {
            setFinished(true);
            onFinished(currentSegment);
            clearInterval(timerHandle);
            timerHandle = 0;
            angleDelta = 0;
        }
    };
    
    
    
    
    

    const wheelDraw = () => {
        clear();
        drawWheel();
        drawNeedle();
    };

    const draw = () => {
        clear();
        drawWheel();
        drawNeedle();
    };

    

    // Define an array of image paths for each segment
    const imagePaths = [
        seg1Image,
        seg2Image,
        seg3Image,
        seg4Image,
        seg5Image,
        seg6Image,
        seg7Image,
        seg8Image,
        seg9Image,
        seg10Image,
        // Add paths for the remaining segments...
    ];


    const drawSegment = (key, lastAngle, angle) => {
        const ctx = canvasContext;
        const value = segments[key];
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(centerX, centerY, size, lastAngle, angle, false);
        ctx.lineTo(centerX, centerY);
        ctx.closePath();
        
        // Set the background color for the specified segment to red
        if (key % 2 === 0) {
        // Even segments
        ctx.fillStyle = "#eaeaeb"; // Example background color for even segments
    } else {
        // Odd segments
        ctx.fillStyle = "white"; // Example background color for odd segments
    }
    const segmentClass = `segment-${key}`;
        
        // Uncomment the following lines to show segment colors
        // ctx.fillStyle = segColors[key];
        ctx.fill();
    
        ctx.stroke();
        ctx.save();
        ctx.translate(centerX, centerY);
        ctx.rotate((lastAngle + angle) / 2);
    
        // Set the fillStyle to black
        ctx.fillStyle = "black";
        
        // ctx.font = "bold 1em " + fontFamily;
        // ctx.fillText(value.substr(0, 21), size / 2 + 20, 0);
        
        // Draw the image for the specified segment
        if (key === 0) {
            const image1 = new Image();
            image1.src = seg1Image; // Path to seg1Image
            image1.onload = () => {
                // Adjust the size of the image to fit within the segment
                const imageSize = size / 4; // Adjust the size as needed
                
                // Calculate the position based on the angle of the segment
                const imageX = Math.cos((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                const imageY = Math.sin((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                
                // Save the current context state
                ctx.save();
                
                // Translate and rotate the context to the correct position and angle
                ctx.translate(centerX + imageX, centerY + imageY);
                ctx.rotate((lastAngle + angle) / 2 + Math.PI / 2); // Add Math.PI / 2 to rotate to the opposite side
                
                // Draw the image at the correct position
                ctx.drawImage(image1, -imageSize / 2, -imageSize / 2, imageSize, imageSize);
                
                // Restore the context state
                ctx.canvas.classList.add(segmentClass);
                ctx.restore();
            };
        
            // const image2 = new Image();
            // image2.src = circ; // Path to the additional image
            // image2.onload = () => {
            //     // Adjust the size of the image to fit within the segment
            //     const imageSize = size / 13; // Adjust the size as needed
            
            //     // Calculate the scaling factor based on the desired height
            //     const scale = imageSize / image2.height;
            
            //     // Calculate the width based on the scaling factor
            //     const imageWidth = image2.width * scale;
            
            //     // Calculate the position based on the starting angle of the segment
            //     const imageX2 = Math.cos(lastAngle) * (size / 2 + imageWidth / 2);
            //     const imageY2 = Math.sin(lastAngle) * (size / 2 + imageSize / 2);
            
            //     // Save the current context state
            //     ctx.save();
            
            //     // Translate the context to the correct position
            //     ctx.translate(400 + imageX2, centerY + imageY2);
            
            //     // Rotate the image to the starting angle of the segment
            //     ctx.rotate(lastAngle + Math.PI / 1); // Adjust the rotation angle as needed
            
            //     // Draw the image at the correct position
            //     ctx.drawImage(image2, -imageWidth / 2, -imageSize / 2, imageWidth, imageSize);
            
            //     // Restore the context state
            //     ctx.restore();
            // };
            

        }
        
        
        else if(key === 1){
            const image = new Image();
            image.src = seg2Image; // Path to seg1Image
            image.onload = () => {
                // Adjust the size of the image to fit within the segment
                const imageSize = size / 4; // Adjust the size as needed
                
                // Calculate the position based on the angle of the segment
                const imageX = Math.cos((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                const imageY = Math.sin((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                
                // Save the current context state
                ctx.save();
                
                // Translate and rotate the context to the correct position and angle
                ctx.translate(centerX + imageX, centerY + imageY);
                ctx.rotate((lastAngle + angle) / 2 + Math.PI / 2); // Add Math.PI / 2 to rotate to the opposite side
                
                // Draw the image at the correct position
                ctx.drawImage(image, -imageSize / 2, -imageSize / 2, imageSize, imageSize);
                
                // Restore the context state
                ctx.canvas.classList.add(segmentClass);
                ctx.restore();
            };
            // const image2 = new Image();
            // image2.src = circ; // Path to the additional image
            // image2.onload = () => {
            //     // Adjust the size of the image to fit within the segment
            //     const imageSize = size / 13; // Adjust the size as needed
            
            //     // Calculate the scaling factor based on the desired height
            //     const scale = imageSize / image2.height;
            
            //     // Calculate the width based on the scaling factor
            //     const imageWidth = image2.width * scale;
            
            //     // Calculate the position based on the starting angle of the segment
            //     const imageX2 = Math.cos(lastAngle) * (size / 2 + imageWidth / 2);
            //     const imageY2 = Math.sin(lastAngle) * (size / 2 + imageSize / 2);
            
            //     // Save the current context state
            //     ctx.save();
            
            //     // Translate the context to the correct position
            //     ctx.translate(291 + imageX2, centerY + imageY2);
            
            //     // Rotate the image to the starting angle of the segment
            //     ctx.rotate(lastAngle + Math.PI / 1); // Adjust the rotation angle as needed
            
            //     // Draw the image at the correct position
            //     ctx.drawImage(image2, -imageWidth / 2, -imageSize / 2, imageWidth, imageSize);
            
            //     // Restore the context state
            //     ctx.restore();
            // };
        }else if(key === 2){
            const image = new Image();
            image.src = seg3Image; // Path to seg1Image
            image.onload = () => {
                // Adjust the size of the image to fit within the segment
                const imageSize = size / 4; // Adjust the size as needed
                
                // Calculate the position based on the angle of the segment
                const imageX = Math.cos((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                const imageY = Math.sin((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                
                // Save the current context state
                ctx.save();
                
                // Translate and rotate the context to the correct position and angle
                ctx.translate(centerX + imageX, centerY + imageY);
                ctx.rotate((lastAngle + angle) / 2 + Math.PI / 2); // Add Math.PI / 2 to rotate to the opposite side
                
                // Draw the image at the correct position
                ctx.drawImage(image, -imageSize / 2, -imageSize / 2, imageSize, imageSize);
                
                // Restore the context state
                ctx.canvas.classList.add(segmentClass);
                ctx.restore();
            };
        }else if(key === 3){
            const image = new Image();
            image.src = seg4Image; // Path to seg1Image
            image.onload = () => {
                // Adjust the size of the image to fit within the segment
                const imageSize = size / 4; // Adjust the size as needed
                
                // Calculate the position based on the angle of the segment
                const imageX = Math.cos((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                const imageY = Math.sin((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                
                // Save the current context state
                ctx.save();
                
                // Translate and rotate the context to the correct position and angle
                ctx.translate(centerX + imageX, centerY + imageY);
                ctx.rotate((lastAngle + angle) / 2 + Math.PI / 2); // Add Math.PI / 2 to rotate to the opposite side
                
                // Draw the image at the correct position
                ctx.drawImage(image, -imageSize / 2, -imageSize / 2, imageSize, imageSize);
                
                // Restore the context state
                ctx.canvas.classList.add(segmentClass);
                ctx.restore();
            };
        }else if(key === 4){
            const image = new Image();
            image.src = seg5Image; // Path to seg1Image
            image.onload = () => {
                // Adjust the size of the image to fit within the segment
                const imageSize = size / 4; // Adjust the size as needed
                
                // Calculate the position based on the angle of the segment
                const imageX = Math.cos((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                const imageY = Math.sin((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                
                // Save the current context state
                ctx.save();
                
                // Translate and rotate the context to the correct position and angle
                ctx.translate(centerX + imageX, centerY + imageY);
                ctx.rotate((lastAngle + angle) / 2 + Math.PI / 2); // Add Math.PI / 2 to rotate to the opposite side
                
                // Draw the image at the correct position
                ctx.drawImage(image, -imageSize / 2, -imageSize / 2, imageSize, imageSize);
                
                // Restore the context state
                ctx.canvas.classList.add(segmentClass);
                ctx.restore();
            };
        }else if(key === 5){
            const image = new Image();
            image.src = seg6Image; // Path to seg1Image
            image.onload = () => {
                // Adjust the size of the image to fit within the segment
                const imageSize = size / 4; // Adjust the size as needed
                
                // Calculate the position based on the angle of the segment
                const imageX = Math.cos((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                const imageY = Math.sin((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                
                // Save the current context state
                ctx.save();
                
                // Translate and rotate the context to the correct position and angle
                ctx.translate(centerX + imageX, centerY + imageY);
                ctx.rotate((lastAngle + angle) / 2 + Math.PI / 2); // Add Math.PI / 2 to rotate to the opposite side
                
                // Draw the image at the correct position
                ctx.drawImage(image, -imageSize / 2, -imageSize / 2, imageSize, imageSize);
                
                // Restore the context state
                ctx.canvas.classList.add(segmentClass);
                ctx.restore();
            };
        }else if(key === 6){
            const image = new Image();
            image.src = seg7Image; // Path to seg1Image
            image.onload = () => {
                // Adjust the size of the image to fit within the segment
                const imageSize = size / 4; // Adjust the size as needed
                
                // Calculate the position based on the angle of the segment
                const imageX = Math.cos((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                const imageY = Math.sin((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                
                // Save the current context state
                ctx.save();
                
                // Translate and rotate the context to the correct position and angle
                ctx.translate(centerX + imageX, centerY + imageY);
                ctx.rotate((lastAngle + angle) / 2 + Math.PI / 2); // Add Math.PI / 2 to rotate to the opposite side
                
                // Draw the image at the correct position
                ctx.drawImage(image, -imageSize / 2, -imageSize / 2, imageSize, imageSize);
                
                // Restore the context state
                ctx.canvas.classList.add(segmentClass);
                ctx.restore();
            };
        }else if(key === 7){
            const image = new Image();
            image.src = seg8Image; // Path to seg1Image
            image.onload = () => {
                // Adjust the size of the image to fit within the segment
                const imageSize = size / 4; // Adjust the size as needed
                
                // Calculate the position based on the angle of the segment
                const imageX = Math.cos((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                const imageY = Math.sin((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                
                // Save the current context state
                ctx.save();
                
                // Translate and rotate the context to the correct position and angle
                ctx.translate(centerX + imageX, centerY + imageY);
                ctx.rotate((lastAngle + angle) / 2 + Math.PI / 2); // Add Math.PI / 2 to rotate to the opposite side
                
                // Draw the image at the correct position
                ctx.drawImage(image, -imageSize / 2, -imageSize / 2, imageSize, imageSize);
                
                // Restore the context state
                ctx.canvas.classList.add(segmentClass);
                ctx.restore();
            };
        }else if(key === 8){
            const image = new Image();
            image.src = seg9Image; // Path to seg1Image
            image.onload = () => {
                // Adjust the size of the image to fit within the segment
                const imageSize = size / 4; // Adjust the size as needed
                
                // Calculate the position based on the angle of the segment
                const imageX = Math.cos((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                const imageY = Math.sin((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                
                // Save the current context state
                ctx.save();
                
                // Translate and rotate the context to the correct position and angle
                ctx.translate(centerX + imageX, centerY + imageY);
                ctx.rotate((lastAngle + angle) / 2 + Math.PI / 2); // Add Math.PI / 2 to rotate to the opposite side
                
                // Draw the image at the correct position
                ctx.drawImage(image, -imageSize / 2, -imageSize / 2, imageSize, imageSize);
                
                // Restore the context state
                ctx.canvas.classList.add(segmentClass);
                ctx.restore();
            };
        }else if(key === 9){
            const image = new Image();
            image.src = seg10Image; // Path to seg1Image
            image.onload = () => {
                // Adjust the size of the image to fit within the segment
                const imageSize = size / 4; // Adjust the size as needed
                
                // Calculate the position based on the angle of the segment
                const imageX = Math.cos((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                const imageY = Math.sin((lastAngle + angle) / 2) * (size / 2 + imageSize); 
                
                // Save the current context state
                ctx.save();
                
                // Translate and rotate the context to the correct position and angle
                ctx.translate(centerX + imageX, centerY + imageY);
                ctx.rotate((lastAngle + angle) / 2 + Math.PI / 2); // Add Math.PI / 2 to rotate to the opposite side
                
                // Draw the image at the correct position
                ctx.drawImage(image, -imageSize / 2, -imageSize / 2, imageSize, imageSize);
                
                // Restore the context state
                ctx.canvas.classList.add(segmentClass);
                ctx.restore();
            };
        }
        
        
        
        ctx.restore();
    };
    
    
    
    
    
    

    const drawWheel = () => {
        const ctx = canvasContext;
        ctx.canvas.classList.add('full-wheel');
        let lastAngle = angleCurrent;
        const len = segments.length;
        const PI2 = Math.PI * 2;
        ctx.lineWidth = 1;
    
        // Remove the white border by setting the strokeStyle to transparent or matching background color
        ctx.strokeStyle = "transparent"; // Change to transparent
    
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.font = "1em " + fontFamily;
        for (let i = 1; i <= len; i++) {
            const angle = PI2 * (i / len) + angleCurrent;
            drawSegment(i - 1, lastAngle, angle);
            lastAngle = angle;
        }
    
        // Draw a center circle
        ctx.beginPath();
        ctx.arc(centerX, centerY, 40, 0, PI2, false);
        ctx.closePath();
        ctx.lineWidth = 5;
        // ctx.strokeStyle = contrastColor || "white";
        ctx.fill();
        ctx.stroke();
    
        // Draw the image for the spin button
        const spinButtonImage = new Image();
        spinButtonImage.src = seg11Image; // Provide the path to your image
        spinButtonImage.onload = () => {
            const imageSize = 150; // Adjust the size of the image as needed
            ctx.drawImage(spinButtonImage, centerX - imageSize / 2, centerY - imageSize / 2, imageSize, imageSize);
        };
    
        // Draw outer circle
        ctx.beginPath();
        ctx.arc(centerX, centerY, size, 0, PI2, false);
        ctx.closePath();
        ctx.lineWidth = 25;
        ctx.stroke();
    };
    
    

    const drawNeedle = () => {
        const ctx = canvasContext;
        ctx.lineWidth = 1;
        ctx.strokeStyle = contrastColor || "white";
        ctx.fileStyle = contrastColor || "white";
        ctx.beginPath();
        ctx.moveTo(centerX + 10, centerY - 40);
        ctx.lineTo(centerX - 10, centerY - 40);
        ctx.lineTo(centerX, centerY - 60);
        ctx.closePath();
        ctx.fill();
        const change = angleCurrent + Math.PI / 2;
        let i =
            segments.length -
            Math.floor((change / (Math.PI * 2)) * segments.length) -
            1;
        if (i < 0) i = i + segments.length;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "transparent";
        ctx.font = "bold 1.5em " + fontFamily;
        currentSegment = segments[i];
        isStarted &&
            ctx.fillText(currentSegment, centerX + 10, centerY + size + 50);

    };
    const clear = () => {
        const ctx = canvasContext;
        ctx.clearRect(0, 0, 1000, 800);
    };
    return (
        <div id="wheel">
            <canvas
                id="canvas"
                width="600"
                height="600"
                style={{
                    pointerEvents: isFinished && isOnlyOnce ? "none" : "auto"
                }}
            />
        </div>
    );
};
export default WheelComponent;