import React from 'react';

const About = () => {
  return (
    <div>
        <h1>hello about</h1>
    </div>
  );
};

export default About;